import { default as indexOe0PK9pyV9Meta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/[roundId]/index.vue?macro=true";
import { default as resultswwKwmvhRSiMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/[roundId]/results.vue?macro=true";
import { default as indexRTWfcwPQwNMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/index.vue?macro=true";
import { default as nonegFjUq4Jgi0Meta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/none.vue?macro=true";
import { default as profileQr34L6hk8JMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/profile.vue?macro=true";
import { default as imagecoW2TXs8dHMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/admin/answer/[answerId]/image.vue?macro=true";
import { default as indexFEWjbch8aZMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/admin/index.vue?macro=true";
import { default as aggregateJ62kXOMJBOMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue?macro=true";
import { default as indexYC3zM5FfTtMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/index.vue?macro=true";
import { default as profilegPka4121JtMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/profile.vue?macro=true";
import { default as results278L8rxQuFMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/results.vue?macro=true";
import { default as _91roundId_93xpCAKFX5VQMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue?macro=true";
import { default as createqlXOBYZM79Meta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue?macro=true";
import { default as _91userId_93qD87RnN92OMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue?macro=true";
import { default as indexri5Q7bSlLLMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue?macro=true";
import { default as createJqoIOxG5D9Meta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/create.vue?macro=true";
import { default as indexl5apMkJKZmMeta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/index.vue?macro=true";
import { default as loginDKX6NeFRE4Meta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/login.vue?macro=true";
import { default as registerCDpi6nk3A6Meta } from "/codebuild/output/src1941389622/src/sense-us.app/pages/register.vue?macro=true";
export default [
  {
    name: indexOe0PK9pyV9Meta?.name ?? "projectId-roundId___en",
    path: indexOe0PK9pyV9Meta?.path ?? "/:projectId()/:roundId()",
    meta: indexOe0PK9pyV9Meta || {},
    alias: indexOe0PK9pyV9Meta?.alias || [],
    redirect: indexOe0PK9pyV9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOe0PK9pyV9Meta?.name ?? "projectId-roundId___nl",
    path: indexOe0PK9pyV9Meta?.path ?? "/nl/:projectId()/:roundId()",
    meta: indexOe0PK9pyV9Meta || {},
    alias: indexOe0PK9pyV9Meta?.alias || [],
    redirect: indexOe0PK9pyV9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOe0PK9pyV9Meta?.name ?? "projectId-roundId___de",
    path: indexOe0PK9pyV9Meta?.path ?? "/de/:projectId()/:roundId()",
    meta: indexOe0PK9pyV9Meta || {},
    alias: indexOe0PK9pyV9Meta?.alias || [],
    redirect: indexOe0PK9pyV9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOe0PK9pyV9Meta?.name ?? "projectId-roundId___es",
    path: indexOe0PK9pyV9Meta?.path ?? "/es/:projectId()/:roundId()",
    meta: indexOe0PK9pyV9Meta || {},
    alias: indexOe0PK9pyV9Meta?.alias || [],
    redirect: indexOe0PK9pyV9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: resultswwKwmvhRSiMeta?.name ?? "projectId-roundId-results___en",
    path: resultswwKwmvhRSiMeta?.path ?? "/:projectId()/:roundId()/results",
    meta: resultswwKwmvhRSiMeta || {},
    alias: resultswwKwmvhRSiMeta?.alias || [],
    redirect: resultswwKwmvhRSiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultswwKwmvhRSiMeta?.name ?? "projectId-roundId-results___nl",
    path: resultswwKwmvhRSiMeta?.path ?? "/nl/:projectId()/:roundId()/results",
    meta: resultswwKwmvhRSiMeta || {},
    alias: resultswwKwmvhRSiMeta?.alias || [],
    redirect: resultswwKwmvhRSiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultswwKwmvhRSiMeta?.name ?? "projectId-roundId-results___de",
    path: resultswwKwmvhRSiMeta?.path ?? "/de/:projectId()/:roundId()/results",
    meta: resultswwKwmvhRSiMeta || {},
    alias: resultswwKwmvhRSiMeta?.alias || [],
    redirect: resultswwKwmvhRSiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultswwKwmvhRSiMeta?.name ?? "projectId-roundId-results___es",
    path: resultswwKwmvhRSiMeta?.path ?? "/es/:projectId()/:roundId()/results",
    meta: resultswwKwmvhRSiMeta || {},
    alias: resultswwKwmvhRSiMeta?.alias || [],
    redirect: resultswwKwmvhRSiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: indexRTWfcwPQwNMeta?.name ?? "projectId___en",
    path: indexRTWfcwPQwNMeta?.path ?? "/:projectId()",
    meta: indexRTWfcwPQwNMeta || {},
    alias: indexRTWfcwPQwNMeta?.alias || [],
    redirect: indexRTWfcwPQwNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRTWfcwPQwNMeta?.name ?? "projectId___nl",
    path: indexRTWfcwPQwNMeta?.path ?? "/nl/:projectId()",
    meta: indexRTWfcwPQwNMeta || {},
    alias: indexRTWfcwPQwNMeta?.alias || [],
    redirect: indexRTWfcwPQwNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRTWfcwPQwNMeta?.name ?? "projectId___de",
    path: indexRTWfcwPQwNMeta?.path ?? "/de/:projectId()",
    meta: indexRTWfcwPQwNMeta || {},
    alias: indexRTWfcwPQwNMeta?.alias || [],
    redirect: indexRTWfcwPQwNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRTWfcwPQwNMeta?.name ?? "projectId___es",
    path: indexRTWfcwPQwNMeta?.path ?? "/es/:projectId()",
    meta: indexRTWfcwPQwNMeta || {},
    alias: indexRTWfcwPQwNMeta?.alias || [],
    redirect: indexRTWfcwPQwNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: nonegFjUq4Jgi0Meta?.name ?? "projectId-none___en",
    path: nonegFjUq4Jgi0Meta?.path ?? "/:projectId()/none",
    meta: nonegFjUq4Jgi0Meta || {},
    alias: nonegFjUq4Jgi0Meta?.alias || [],
    redirect: nonegFjUq4Jgi0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: nonegFjUq4Jgi0Meta?.name ?? "projectId-none___nl",
    path: nonegFjUq4Jgi0Meta?.path ?? "/nl/:projectId()/none",
    meta: nonegFjUq4Jgi0Meta || {},
    alias: nonegFjUq4Jgi0Meta?.alias || [],
    redirect: nonegFjUq4Jgi0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: nonegFjUq4Jgi0Meta?.name ?? "projectId-none___de",
    path: nonegFjUq4Jgi0Meta?.path ?? "/de/:projectId()/none",
    meta: nonegFjUq4Jgi0Meta || {},
    alias: nonegFjUq4Jgi0Meta?.alias || [],
    redirect: nonegFjUq4Jgi0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: nonegFjUq4Jgi0Meta?.name ?? "projectId-none___es",
    path: nonegFjUq4Jgi0Meta?.path ?? "/es/:projectId()/none",
    meta: nonegFjUq4Jgi0Meta || {},
    alias: nonegFjUq4Jgi0Meta?.alias || [],
    redirect: nonegFjUq4Jgi0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: profileQr34L6hk8JMeta?.name ?? "projectId-profile___en",
    path: profileQr34L6hk8JMeta?.path ?? "/:projectId()/profile",
    meta: profileQr34L6hk8JMeta || {},
    alias: profileQr34L6hk8JMeta?.alias || [],
    redirect: profileQr34L6hk8JMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileQr34L6hk8JMeta?.name ?? "projectId-profile___nl",
    path: profileQr34L6hk8JMeta?.path ?? "/nl/:projectId()/profile",
    meta: profileQr34L6hk8JMeta || {},
    alias: profileQr34L6hk8JMeta?.alias || [],
    redirect: profileQr34L6hk8JMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileQr34L6hk8JMeta?.name ?? "projectId-profile___de",
    path: profileQr34L6hk8JMeta?.path ?? "/de/:projectId()/profile",
    meta: profileQr34L6hk8JMeta || {},
    alias: profileQr34L6hk8JMeta?.alias || [],
    redirect: profileQr34L6hk8JMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileQr34L6hk8JMeta?.name ?? "projectId-profile___es",
    path: profileQr34L6hk8JMeta?.path ?? "/es/:projectId()/profile",
    meta: profileQr34L6hk8JMeta || {},
    alias: profileQr34L6hk8JMeta?.alias || [],
    redirect: profileQr34L6hk8JMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: imagecoW2TXs8dHMeta?.name ?? "admin-answer-answerId-image___en",
    path: imagecoW2TXs8dHMeta?.path ?? "/admin/answer/:answerId()/image",
    meta: imagecoW2TXs8dHMeta || {},
    alias: imagecoW2TXs8dHMeta?.alias || [],
    redirect: imagecoW2TXs8dHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: imagecoW2TXs8dHMeta?.name ?? "admin-answer-answerId-image___nl",
    path: imagecoW2TXs8dHMeta?.path ?? "/nl/admin/answer/:answerId()/image",
    meta: imagecoW2TXs8dHMeta || {},
    alias: imagecoW2TXs8dHMeta?.alias || [],
    redirect: imagecoW2TXs8dHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: imagecoW2TXs8dHMeta?.name ?? "admin-answer-answerId-image___de",
    path: imagecoW2TXs8dHMeta?.path ?? "/de/admin/answer/:answerId()/image",
    meta: imagecoW2TXs8dHMeta || {},
    alias: imagecoW2TXs8dHMeta?.alias || [],
    redirect: imagecoW2TXs8dHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: imagecoW2TXs8dHMeta?.name ?? "admin-answer-answerId-image___es",
    path: imagecoW2TXs8dHMeta?.path ?? "/es/admin/answer/:answerId()/image",
    meta: imagecoW2TXs8dHMeta || {},
    alias: imagecoW2TXs8dHMeta?.alias || [],
    redirect: imagecoW2TXs8dHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: indexFEWjbch8aZMeta?.name ?? "admin___en",
    path: indexFEWjbch8aZMeta?.path ?? "/admin",
    meta: indexFEWjbch8aZMeta || {},
    alias: indexFEWjbch8aZMeta?.alias || [],
    redirect: indexFEWjbch8aZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexFEWjbch8aZMeta?.name ?? "admin___nl",
    path: indexFEWjbch8aZMeta?.path ?? "/nl/admin",
    meta: indexFEWjbch8aZMeta || {},
    alias: indexFEWjbch8aZMeta?.alias || [],
    redirect: indexFEWjbch8aZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexFEWjbch8aZMeta?.name ?? "admin___de",
    path: indexFEWjbch8aZMeta?.path ?? "/de/admin",
    meta: indexFEWjbch8aZMeta || {},
    alias: indexFEWjbch8aZMeta?.alias || [],
    redirect: indexFEWjbch8aZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexFEWjbch8aZMeta?.name ?? "admin___es",
    path: indexFEWjbch8aZMeta?.path ?? "/es/admin",
    meta: indexFEWjbch8aZMeta || {},
    alias: indexFEWjbch8aZMeta?.alias || [],
    redirect: indexFEWjbch8aZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: aggregateJ62kXOMJBOMeta?.name ?? "admin-project-projectId-aggregate___en",
    path: aggregateJ62kXOMJBOMeta?.path ?? "/admin/project/:projectId()/aggregate",
    meta: aggregateJ62kXOMJBOMeta || {},
    alias: aggregateJ62kXOMJBOMeta?.alias || [],
    redirect: aggregateJ62kXOMJBOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregateJ62kXOMJBOMeta?.name ?? "admin-project-projectId-aggregate___nl",
    path: aggregateJ62kXOMJBOMeta?.path ?? "/nl/admin/project/:projectId()/aggregate",
    meta: aggregateJ62kXOMJBOMeta || {},
    alias: aggregateJ62kXOMJBOMeta?.alias || [],
    redirect: aggregateJ62kXOMJBOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregateJ62kXOMJBOMeta?.name ?? "admin-project-projectId-aggregate___de",
    path: aggregateJ62kXOMJBOMeta?.path ?? "/de/admin/project/:projectId()/aggregate",
    meta: aggregateJ62kXOMJBOMeta || {},
    alias: aggregateJ62kXOMJBOMeta?.alias || [],
    redirect: aggregateJ62kXOMJBOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregateJ62kXOMJBOMeta?.name ?? "admin-project-projectId-aggregate___es",
    path: aggregateJ62kXOMJBOMeta?.path ?? "/es/admin/project/:projectId()/aggregate",
    meta: aggregateJ62kXOMJBOMeta || {},
    alias: aggregateJ62kXOMJBOMeta?.alias || [],
    redirect: aggregateJ62kXOMJBOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: indexYC3zM5FfTtMeta?.name ?? "admin-project-projectId___en",
    path: indexYC3zM5FfTtMeta?.path ?? "/admin/project/:projectId()",
    meta: indexYC3zM5FfTtMeta || {},
    alias: indexYC3zM5FfTtMeta?.alias || [],
    redirect: indexYC3zM5FfTtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexYC3zM5FfTtMeta?.name ?? "admin-project-projectId___nl",
    path: indexYC3zM5FfTtMeta?.path ?? "/nl/admin/project/:projectId()",
    meta: indexYC3zM5FfTtMeta || {},
    alias: indexYC3zM5FfTtMeta?.alias || [],
    redirect: indexYC3zM5FfTtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexYC3zM5FfTtMeta?.name ?? "admin-project-projectId___de",
    path: indexYC3zM5FfTtMeta?.path ?? "/de/admin/project/:projectId()",
    meta: indexYC3zM5FfTtMeta || {},
    alias: indexYC3zM5FfTtMeta?.alias || [],
    redirect: indexYC3zM5FfTtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexYC3zM5FfTtMeta?.name ?? "admin-project-projectId___es",
    path: indexYC3zM5FfTtMeta?.path ?? "/es/admin/project/:projectId()",
    meta: indexYC3zM5FfTtMeta || {},
    alias: indexYC3zM5FfTtMeta?.alias || [],
    redirect: indexYC3zM5FfTtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: profilegPka4121JtMeta?.name ?? "admin-project-projectId-profile___en",
    path: profilegPka4121JtMeta?.path ?? "/admin/project/:projectId()/profile",
    meta: profilegPka4121JtMeta || {},
    alias: profilegPka4121JtMeta?.alias || [],
    redirect: profilegPka4121JtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profilegPka4121JtMeta?.name ?? "admin-project-projectId-profile___nl",
    path: profilegPka4121JtMeta?.path ?? "/nl/admin/project/:projectId()/profile",
    meta: profilegPka4121JtMeta || {},
    alias: profilegPka4121JtMeta?.alias || [],
    redirect: profilegPka4121JtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profilegPka4121JtMeta?.name ?? "admin-project-projectId-profile___de",
    path: profilegPka4121JtMeta?.path ?? "/de/admin/project/:projectId()/profile",
    meta: profilegPka4121JtMeta || {},
    alias: profilegPka4121JtMeta?.alias || [],
    redirect: profilegPka4121JtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profilegPka4121JtMeta?.name ?? "admin-project-projectId-profile___es",
    path: profilegPka4121JtMeta?.path ?? "/es/admin/project/:projectId()/profile",
    meta: profilegPka4121JtMeta || {},
    alias: profilegPka4121JtMeta?.alias || [],
    redirect: profilegPka4121JtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: results278L8rxQuFMeta?.name ?? "admin-project-projectId-results___en",
    path: results278L8rxQuFMeta?.path ?? "/admin/project/:projectId()/results",
    meta: results278L8rxQuFMeta || {},
    alias: results278L8rxQuFMeta?.alias || [],
    redirect: results278L8rxQuFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: results278L8rxQuFMeta?.name ?? "admin-project-projectId-results___nl",
    path: results278L8rxQuFMeta?.path ?? "/nl/admin/project/:projectId()/results",
    meta: results278L8rxQuFMeta || {},
    alias: results278L8rxQuFMeta?.alias || [],
    redirect: results278L8rxQuFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: results278L8rxQuFMeta?.name ?? "admin-project-projectId-results___de",
    path: results278L8rxQuFMeta?.path ?? "/de/admin/project/:projectId()/results",
    meta: results278L8rxQuFMeta || {},
    alias: results278L8rxQuFMeta?.alias || [],
    redirect: results278L8rxQuFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: results278L8rxQuFMeta?.name ?? "admin-project-projectId-results___es",
    path: results278L8rxQuFMeta?.path ?? "/es/admin/project/:projectId()/results",
    meta: results278L8rxQuFMeta || {},
    alias: results278L8rxQuFMeta?.alias || [],
    redirect: results278L8rxQuFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93xpCAKFX5VQMeta?.name ?? "admin-project-projectId-round-roundId___en",
    path: _91roundId_93xpCAKFX5VQMeta?.path ?? "/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93xpCAKFX5VQMeta || {},
    alias: _91roundId_93xpCAKFX5VQMeta?.alias || [],
    redirect: _91roundId_93xpCAKFX5VQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93xpCAKFX5VQMeta?.name ?? "admin-project-projectId-round-roundId___nl",
    path: _91roundId_93xpCAKFX5VQMeta?.path ?? "/nl/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93xpCAKFX5VQMeta || {},
    alias: _91roundId_93xpCAKFX5VQMeta?.alias || [],
    redirect: _91roundId_93xpCAKFX5VQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93xpCAKFX5VQMeta?.name ?? "admin-project-projectId-round-roundId___de",
    path: _91roundId_93xpCAKFX5VQMeta?.path ?? "/de/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93xpCAKFX5VQMeta || {},
    alias: _91roundId_93xpCAKFX5VQMeta?.alias || [],
    redirect: _91roundId_93xpCAKFX5VQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93xpCAKFX5VQMeta?.name ?? "admin-project-projectId-round-roundId___es",
    path: _91roundId_93xpCAKFX5VQMeta?.path ?? "/es/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93xpCAKFX5VQMeta || {},
    alias: _91roundId_93xpCAKFX5VQMeta?.alias || [],
    redirect: _91roundId_93xpCAKFX5VQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: createqlXOBYZM79Meta?.name ?? "admin-project-projectId-round-create___en",
    path: createqlXOBYZM79Meta?.path ?? "/admin/project/:projectId()/round/create",
    meta: createqlXOBYZM79Meta || {},
    alias: createqlXOBYZM79Meta?.alias || [],
    redirect: createqlXOBYZM79Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: createqlXOBYZM79Meta?.name ?? "admin-project-projectId-round-create___nl",
    path: createqlXOBYZM79Meta?.path ?? "/nl/admin/project/:projectId()/round/create",
    meta: createqlXOBYZM79Meta || {},
    alias: createqlXOBYZM79Meta?.alias || [],
    redirect: createqlXOBYZM79Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: createqlXOBYZM79Meta?.name ?? "admin-project-projectId-round-create___de",
    path: createqlXOBYZM79Meta?.path ?? "/de/admin/project/:projectId()/round/create",
    meta: createqlXOBYZM79Meta || {},
    alias: createqlXOBYZM79Meta?.alias || [],
    redirect: createqlXOBYZM79Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: createqlXOBYZM79Meta?.name ?? "admin-project-projectId-round-create___es",
    path: createqlXOBYZM79Meta?.path ?? "/es/admin/project/:projectId()/round/create",
    meta: createqlXOBYZM79Meta || {},
    alias: createqlXOBYZM79Meta?.alias || [],
    redirect: createqlXOBYZM79Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: _91userId_93qD87RnN92OMeta?.name ?? "admin-project-projectId-users-userId___en",
    path: _91userId_93qD87RnN92OMeta?.path ?? "/admin/project/:projectId()/users/:userId()",
    meta: _91userId_93qD87RnN92OMeta || {},
    alias: _91userId_93qD87RnN92OMeta?.alias || [],
    redirect: _91userId_93qD87RnN92OMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_93qD87RnN92OMeta?.name ?? "admin-project-projectId-users-userId___nl",
    path: _91userId_93qD87RnN92OMeta?.path ?? "/nl/admin/project/:projectId()/users/:userId()",
    meta: _91userId_93qD87RnN92OMeta || {},
    alias: _91userId_93qD87RnN92OMeta?.alias || [],
    redirect: _91userId_93qD87RnN92OMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_93qD87RnN92OMeta?.name ?? "admin-project-projectId-users-userId___de",
    path: _91userId_93qD87RnN92OMeta?.path ?? "/de/admin/project/:projectId()/users/:userId()",
    meta: _91userId_93qD87RnN92OMeta || {},
    alias: _91userId_93qD87RnN92OMeta?.alias || [],
    redirect: _91userId_93qD87RnN92OMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_93qD87RnN92OMeta?.name ?? "admin-project-projectId-users-userId___es",
    path: _91userId_93qD87RnN92OMeta?.path ?? "/es/admin/project/:projectId()/users/:userId()",
    meta: _91userId_93qD87RnN92OMeta || {},
    alias: _91userId_93qD87RnN92OMeta?.alias || [],
    redirect: _91userId_93qD87RnN92OMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: indexri5Q7bSlLLMeta?.name ?? "admin-project-projectId-users___en",
    path: indexri5Q7bSlLLMeta?.path ?? "/admin/project/:projectId()/users",
    meta: indexri5Q7bSlLLMeta || {},
    alias: indexri5Q7bSlLLMeta?.alias || [],
    redirect: indexri5Q7bSlLLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexri5Q7bSlLLMeta?.name ?? "admin-project-projectId-users___nl",
    path: indexri5Q7bSlLLMeta?.path ?? "/nl/admin/project/:projectId()/users",
    meta: indexri5Q7bSlLLMeta || {},
    alias: indexri5Q7bSlLLMeta?.alias || [],
    redirect: indexri5Q7bSlLLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexri5Q7bSlLLMeta?.name ?? "admin-project-projectId-users___de",
    path: indexri5Q7bSlLLMeta?.path ?? "/de/admin/project/:projectId()/users",
    meta: indexri5Q7bSlLLMeta || {},
    alias: indexri5Q7bSlLLMeta?.alias || [],
    redirect: indexri5Q7bSlLLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexri5Q7bSlLLMeta?.name ?? "admin-project-projectId-users___es",
    path: indexri5Q7bSlLLMeta?.path ?? "/es/admin/project/:projectId()/users",
    meta: indexri5Q7bSlLLMeta || {},
    alias: indexri5Q7bSlLLMeta?.alias || [],
    redirect: indexri5Q7bSlLLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: createJqoIOxG5D9Meta?.name ?? "admin-project-create___en",
    path: createJqoIOxG5D9Meta?.path ?? "/admin/project/create",
    meta: createJqoIOxG5D9Meta || {},
    alias: createJqoIOxG5D9Meta?.alias || [],
    redirect: createJqoIOxG5D9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createJqoIOxG5D9Meta?.name ?? "admin-project-create___nl",
    path: createJqoIOxG5D9Meta?.path ?? "/nl/admin/project/create",
    meta: createJqoIOxG5D9Meta || {},
    alias: createJqoIOxG5D9Meta?.alias || [],
    redirect: createJqoIOxG5D9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createJqoIOxG5D9Meta?.name ?? "admin-project-create___de",
    path: createJqoIOxG5D9Meta?.path ?? "/de/admin/project/create",
    meta: createJqoIOxG5D9Meta || {},
    alias: createJqoIOxG5D9Meta?.alias || [],
    redirect: createJqoIOxG5D9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createJqoIOxG5D9Meta?.name ?? "admin-project-create___es",
    path: createJqoIOxG5D9Meta?.path ?? "/es/admin/project/create",
    meta: createJqoIOxG5D9Meta || {},
    alias: createJqoIOxG5D9Meta?.alias || [],
    redirect: createJqoIOxG5D9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: indexl5apMkJKZmMeta?.name ?? "index___en",
    path: indexl5apMkJKZmMeta?.path ?? "/",
    meta: indexl5apMkJKZmMeta || {},
    alias: indexl5apMkJKZmMeta?.alias || [],
    redirect: indexl5apMkJKZmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexl5apMkJKZmMeta?.name ?? "index___nl",
    path: indexl5apMkJKZmMeta?.path ?? "/nl",
    meta: indexl5apMkJKZmMeta || {},
    alias: indexl5apMkJKZmMeta?.alias || [],
    redirect: indexl5apMkJKZmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexl5apMkJKZmMeta?.name ?? "index___de",
    path: indexl5apMkJKZmMeta?.path ?? "/de",
    meta: indexl5apMkJKZmMeta || {},
    alias: indexl5apMkJKZmMeta?.alias || [],
    redirect: indexl5apMkJKZmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexl5apMkJKZmMeta?.name ?? "index___es",
    path: indexl5apMkJKZmMeta?.path ?? "/es",
    meta: indexl5apMkJKZmMeta || {},
    alias: indexl5apMkJKZmMeta?.alias || [],
    redirect: indexl5apMkJKZmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginDKX6NeFRE4Meta?.name ?? "login___en",
    path: loginDKX6NeFRE4Meta?.path ?? "/login",
    meta: loginDKX6NeFRE4Meta || {},
    alias: loginDKX6NeFRE4Meta?.alias || [],
    redirect: loginDKX6NeFRE4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginDKX6NeFRE4Meta?.name ?? "login___nl",
    path: loginDKX6NeFRE4Meta?.path ?? "/nl/login",
    meta: loginDKX6NeFRE4Meta || {},
    alias: loginDKX6NeFRE4Meta?.alias || [],
    redirect: loginDKX6NeFRE4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginDKX6NeFRE4Meta?.name ?? "login___de",
    path: loginDKX6NeFRE4Meta?.path ?? "/de/login",
    meta: loginDKX6NeFRE4Meta || {},
    alias: loginDKX6NeFRE4Meta?.alias || [],
    redirect: loginDKX6NeFRE4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginDKX6NeFRE4Meta?.name ?? "login___es",
    path: loginDKX6NeFRE4Meta?.path ?? "/es/login",
    meta: loginDKX6NeFRE4Meta || {},
    alias: loginDKX6NeFRE4Meta?.alias || [],
    redirect: loginDKX6NeFRE4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerCDpi6nk3A6Meta?.name ?? "register___en",
    path: registerCDpi6nk3A6Meta?.path ?? "/register",
    meta: registerCDpi6nk3A6Meta || {},
    alias: registerCDpi6nk3A6Meta?.alias || [],
    redirect: registerCDpi6nk3A6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerCDpi6nk3A6Meta?.name ?? "register___nl",
    path: registerCDpi6nk3A6Meta?.path ?? "/nl/register",
    meta: registerCDpi6nk3A6Meta || {},
    alias: registerCDpi6nk3A6Meta?.alias || [],
    redirect: registerCDpi6nk3A6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerCDpi6nk3A6Meta?.name ?? "register___de",
    path: registerCDpi6nk3A6Meta?.path ?? "/de/register",
    meta: registerCDpi6nk3A6Meta || {},
    alias: registerCDpi6nk3A6Meta?.alias || [],
    redirect: registerCDpi6nk3A6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerCDpi6nk3A6Meta?.name ?? "register___es",
    path: registerCDpi6nk3A6Meta?.path ?? "/es/register",
    meta: registerCDpi6nk3A6Meta || {},
    alias: registerCDpi6nk3A6Meta?.alias || [],
    redirect: registerCDpi6nk3A6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1941389622/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  }
]