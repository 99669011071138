<script setup>
import { navigateTo, useError, useRoute } from '#app'
import { computed } from 'vue'
import { useAuthenticator } from '@aws-amplify/ui-vue'
import * as Sentry from '@sentry/vue'
import PublicLayout from '~/layouts/public.vue'

const { $isAdmin: isAdmin } = useNuxtApp()
const error = useError()
const auth = useAuthenticator()
const route = useRoute()

const logout = async () => {
  await auth.signOut()
  return navigateTo(`/login?redirect=${encodeURIComponent(route.fullPath)}`)
}

if (error.value.statusCode !== 404 && error.value.statusCode !== 403) {
  const report = (e) => {
    console.log('Reporting error', e)
    const err = e instanceof Error ? e : new Error(`Sense error: ${e.message || e}`)
    Sentry.captureException(err, {
      extra: e,
    })

    if (e.cause instanceof Error) {
      report(e.cause)
    }
    if (e.errors instanceof Array) {
      e.errors.forEach((ee) => report(ee))
    }
    if (e.cause?.errors instanceof Array) {
      e.cause.errors.forEach((ee) => report(ee))
    }
  }
  report(error.value)
}

const stacktrace = computed(() =>
  (error.stack || '')
    .split('\n')
    .splice(1)
    .map((line) => {
      const text = line
        .replace('webpack:/', '')
        .replace('.vue', '.js') // TODO: Support sourcemap
        .trim()
      return {
        text,
        internal:
          (line.includes('node_modules') && !line.includes('.cache')) ||
          line.includes('internal') ||
          line.includes('new Promise'),
      }
    })
    .map((i) => `<span class="stack${i.internal ? ' internal' : ''}">${i.text}</span>`)
    .join('\n')
)

// Force reload the page one time if the error is not 404 or 403
const forceReloadIfNeeded = () => {
  if (error.value.statusCode !== 404 && error.value.statusCode !== 403) {
    if (!route.query.reloaded) {
      let url = window.location.href
      url += (url.includes('?') ? '&' : '?') + 'reloaded=1'
      window.location.replace(url)
    }
  }
}

forceReloadIfNeeded()
</script>

<template>
  <PublicLayout>
    <div class="prose">
      <template v-if="error.statusCode === 404">
        <h1>{{ $t('error.pageNotFoundTitle') }}</h1>
        <p v-if="error.message">{{ error.message }}</p>
        <p v-else>{{ $t('error.pageNotFound') }}</p>
      </template>
      <template v-else-if="error.statusCode === 403">
        <h1>{{ $t('error.insufficientPermissionsTitle') }}</h1>
        <p>{{ $t('error.insufficientPermissions') }}</p>
        <el-button type="primary" @click.prevent="logout">{{ $t('button.logout') }}</el-button>
      </template>
      <template v-else>
        <h1>{{ $t('error.generalErrorTitle') }}</h1>
        <p>{{ $t('error.generalError') }}</p>
      </template>
      <i18n-t tag="p" keypath="user.page.error.errorHint">
        <template #home>
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          <a v-if="isAdmin" href="/admin">admin dashboard</a>
          <a v-else href="/">{{ $t('user.tab.home').toLowerCase() }}</a>
        </template>
      </i18n-t>
      <details v-if="isAdmin && error.statusCode !== 404 && error.statusCode !== 403">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <summary>Error details</summary>
        <pre>{{ error }}</pre>
        <pre v-if="stacktrace">{{ stacktrace }}</pre>
      </details>
    </div>
  </PublicLayout>
</template>

<style scoped lang="scss">
@use 'sass:map';

.prose {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: map.get(element.$header, 'height');
  margin-bottom: map.get(element.$footer, 'height');
}
</style>
