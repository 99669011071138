import { abortNavigation, defineNuxtRouteMiddleware, navigateTo } from '#app'

export default defineNuxtRouteMiddleware(async (to, from) => {
  console.log('nav', { to, from })
  if (to.meta.public) {
    return
  }

  const { $authenticated, $isAdmin, $authLoading, $authPromise, $isProjectAdmin, $roles } =
    useNuxtApp()

  if ($authLoading.value) {
    console.log('waiting for authPromise')
    await $authPromise.value
  }

  console.debug('auth.global.js', {
    meta: to.meta,
    $authenticated: $authenticated.value,
    $isAdmin: $isAdmin.value,
  })
  if (!$authenticated.value) {
    console.log('redirecting to login', { path: to.fullPath })
    return navigateTo(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
  }

  if (to.meta?.admin && !$isAdmin.value) {
    // Check if user is a project admin
    if ($isProjectAdmin.value) {
      // Allow project admins to access admin index page
      if (to.path === '/admin' || to.path === '/admin/') {
        return
      }
      // Check if user is a project admin for the project in the route
      if (to.params?.projectId) {
        const projectAdminGroup = `project-admin-${to.params.projectId}`
        if ($roles.value.includes(projectAdminGroup)) {
          return
        }
      }
    }

    console.log('aborting due to not admin', { path: to.fullPath })
    return abortNavigation({
      statusCode: 403,
      message: 'Insufficient permission',
      statusMessage: 'Insufficient permission',
    })
  }
})
