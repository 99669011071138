import { defineNuxtPlugin } from '#app'
import { API } from 'aws-amplify'
import { getI18nStrings } from '~/src/graphql/queries'

export default defineNuxtPlugin({
  name: 'loadI18nMessages',
  parallel: true,
  async setup() {
    try {
      if (!import.meta.env.LOAD_I18N_MESSAGES) {
        return
      }

      const { $authPromise, $isAdmin, $i18n } = useNuxtApp()

      await $authPromise.value

      if (!$isAdmin.value) {
        console.debug('not an admin, skipping i18n messages')
        return
      }

      const response = await API.graphql({
        query: getI18nStrings,
      })

      const messages = JSON.parse(response.data.getI18nStrings)

      Object.entries(messages).forEach(([locale, messages]) => {
        $i18n.setLocaleMessage(locale, messages)
      })

      console.log('loaded i18n messages')
    } catch (error) {
      console.error('error loading i18n messages', error)
    }
  },
})
