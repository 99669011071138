import * as Sentry from '@sentry/vue'
import { defineNuxtPlugin, useRouter } from '#app'
export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const SENTRY_ENABLED = import.meta.env.SENTRY_ENABLED

  if (!SENTRY_ENABLED) {
    console.debug('Sentry not enabled', __RELEASE__)
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: import.meta.env.SENTRY_DSN,
    environment: import.meta.env.APP_ENV,
    release: __RELEASE__,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    tracePropagationTargets: ['localhost', '*.sense-us.app'],
  })
})
