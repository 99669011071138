export const loadScript = (url, options = {}) => {
  return new Promise((resolve, reject) => {
    if (typeof document === 'undefined') {
      return
    }

    const head = document.head || document.getElementsByTagName('head')[0]
    const script = document.createElement('script')

    script.async = true
    script.src = url
    script.defer = options.defer

    if (options.preconnectOrigin) {
      const link = document.createElement('link')

      link.href = options.preconnectOrigin
      link.rel = 'preconnect'

      head.appendChild(link)
    }

    head.appendChild(script)

    script.onload = resolve
    script.onerror = reject
  })
}
