import { defineNuxtPlugin } from '#app'
import { ref } from 'vue'
import { loadScript } from '~/src/utils/dom'

export default defineNuxtPlugin(() => {
  const isCookieConsentLoaded = ref(false)
  const _iub = window._iub || []

  try {
    _iub.csConfiguration = {
      askConsentAtCookiePolicyUpdate: true,
      enableFadp: true,
      enableLgpd: true,
      fadpApplies: true,
      floatingPreferencesButtonDisplay: false,
      lang: 'en-GB',
      perPurposeConsent: true,
      siteId: 3458776,
      whitelabel: false,
      cookiePolicyId: 67294887,
      cookiePolicyUrl: 'https://www.voicesthatcount.net/sense-us-cookie-policy',
      banner: {
        acceptButtonDisplay: true,
        closeButtonDisplay: false,
        customizeButtonDisplay: true,
        explicitWithdrawal: true,
        listPurposes: true,
        position: 'float-bottom-right',
        rejectButtonDisplay: true,
        showTitle: false,
      },
      callback: {
        onReady: (params) => {
          console.log('Iubenda loaded', params)
          isCookieConsentLoaded.value = true
        },
        onStartupFailed: (params) => {
          console.error('Iubenda failed to load', params)
        },
        onError: (params) => {
          console.error('Iubenda error', params)
        },
        onFatalError: (params) => {
          console.error('Iubenda fatal error', params)
        },
      },
    }

    window._iub = _iub

    loadScript('https://cs.iubenda.com/autoblocking/3458776.js').catch((e) =>
      console.error('Iubenda script failed to load', e)
    )
    loadScript('//cdn.iubenda.com/cs/gpp/stub.js').catch((e) =>
      console.error('Iubenda script failed to load', e)
    )
    loadScript('//cdn.iubenda.com/cs/iubenda_cs.js').catch((e) =>
      console.error('Iubenda script failed to load', e)
    )
  } catch (error) {
    console.error('Iubenda failed to load', error)
  }

  const showConsentConfiguration = () => {
    _iub.cs?.api?.openPreferences()
  }

  const hasConsent = () => {
    return _iub?.cs?.api?.isConsentGiven()
  }

  return {
    provide: {
      isCookieConsentLoaded,
      hasConsent,
      showConsentConfiguration,
    },
  }
})
