import { Amplify } from '@aws-amplify/core'
import { Auth, Hub } from 'aws-amplify'
import { computed, ref, watchEffect } from 'vue'
import { defineNuxtPlugin } from '#app'
import awsConfig from '../src/aws-exports'

const listener = (data) => {
  console.debug('auth listener', data)
  switch (data?.payload?.event) {
    case 'configured':
      console.info('the Auth module is configured')
      break
    case 'signIn':
      console.info('user signed in')
      break
    case 'signIn_failure':
      console.error('user sign in failed')
      break
    case 'signUp':
      console.info('user signed up')
      break
    case 'signUp_failure':
      console.error('user sign up failed')
      break
    case 'confirmSignUp':
      console.info('user confirmation successful')
      break
    case 'completeNewPassword_failure':
      console.error('user did not complete new password flow')
      break
    case 'autoSignIn':
      console.info('auto sign in successful')
      break
    case 'autoSignIn_failure':
      console.error('auto sign in failed')
      break
    case 'forgotPassword':
      console.info('password recovery initiated')
      break
    case 'forgotPassword_failure':
      console.error('password recovery failed')
      break
    case 'forgotPasswordSubmit':
      console.info('password confirmation successful')
      break
    case 'forgotPasswordSubmit_failure':
      console.error('password confirmation failed')
      break
    case 'verify':
      console.info('TOTP token verification successful')
      break
    case 'tokenRefresh':
      console.info('token refresh succeeded')
      break
    case 'tokenRefresh_failure':
      console.error('token refresh failed')
      break
    case 'cognitoHostedUI':
      console.info('Cognito Hosted UI sign in successful')
      break
    case 'cognitoHostedUI_failure':
      console.error('Cognito Hosted UI sign in failed')
      break
    case 'customOAuthState':
      console.info('custom state returned from CognitoHosted UI')
      break
    case 'customState_failure':
      console.error('custom state failure')
      break
    case 'parsingCallbackUrl':
      console.info('Cognito Hosted UI OAuth url parsing initiated')
      break
    case 'userDeleted':
      console.info('user deletion successful')
      break
    case 'updateUserAttributes':
      console.info('user attributes update successful')
      break
    case 'updateUserAttributes_failure':
      console.info('user attributes update failed')
      break
    case 'signOut':
      console.info('user signed out')
      break
    default:
      console.info('unknown event type')
      break
  }
}

Hub.listen('auth', listener)

async function loadAuthState({ bypassCache } = {}) {
  const [forcedUser] = bypassCache
    ? await Promise.allSettled([Auth.currentAuthenticatedUser({ bypassCache })])
    : [null]

  // noinspection ES6MissingAwait
  const [user, session, credentials] = [
    ...(bypassCache ? [forcedUser] : []),
    ...(await Promise.allSettled([
      ...(!bypassCache ? [Auth.currentAuthenticatedUser()] : []),
      Auth.currentSession(),
      Auth.currentCredentials(),
    ])),
  ]

  if (user.status === 'rejected') {
    console.warn('unable to fetch user', user.reason)
  } else {
    console.log('user', user.value)
  }
  if (session.status === 'rejected') {
    console.warn('unable to fetch session', session.reason)
  } else {
    console.log('session', session.value)
  }
  if (credentials.status === 'rejected') {
    console.warn('unable to fetch credentials', credentials.reason)
  } else {
    console.log('credentials', credentials.value)
  }

  return {
    user: user.value,
    session: session.value,
    credentials: credentials.value,
  }
}

export default defineNuxtPlugin(() => {
  const authState = ref(null)
  const authLoading = ref(true)

  let resolveAuthPromise
  let rejectAuthPromise
  const authPromise = ref(
    new Promise((resolve, reject) => {
      resolveAuthPromise = resolve
      rejectAuthPromise = reject
    })
  )

  const load = ({ bypassCache } = {}) => {
    authLoading.value = true
    authPromise.value = loadAuthState({ bypassCache })
    if (resolveAuthPromise) {
      authPromise.value.then(resolveAuthPromise, rejectAuthPromise)
      resolveAuthPromise = null
      rejectAuthPromise = null
    }
    authPromise.value
      .then(
        (state) => (authState.value = state),
        (error) => {
          console.error('unable to load auth state', error)
          authState.value = null
        }
      )
      .finally(() => {
        authLoading.value = false
      })
  }

  Hub.listen('auth', () => {
    load()
  })

  const credentials = computed(() => authState.value?.credentials)
  const session = computed(() => authState.value?.session)
  const user = computed(() => authState.value?.user)
  const authToken = computed(() => session.value?.getIdToken().getJwtToken())
  const authenticated = computed(() => credentials.value?.authenticated)
  const roles = computed(
    () =>
      (
        authState.value?.user?.signInUserSession?.accessToken ||
        authState.value?.user?.value?.signInUserSession?.idToken
      )?.payload['cognito:groups']
  )
  const isAdmin = computed(() => roles.value?.includes('admins'))
  const userId = computed(() => `${user.value?.attributes?.sub}::${user.value?.username}`)
  const isProjectAdmin = computed(() =>
    roles.value?.some((role) => role.startsWith('project-admin-'))
  )

  Amplify.configure(awsConfig)

  // try to refresh the credentials 5 minutes before they expire
  watchEffect((onCleanup) => {
    const tryRefresh = () => {
      try {
        console.log('refreshing credentials')
        load({ bypassCache: true })
      } catch (error) {
        console.warn('unable to refresh credentials', error)
      }
    }

    if (authState.value?.user?.signInUserSession?.accessToken?.payload?.exp) {
      const now = Date.now()
      const adjusted = now + 1000 * 60 * 1
      const remaining =
        authState.value.user.signInUserSession.accessToken.payload.exp * 1000 - adjusted
      console.log('refresh credentials in', remaining / 1000, 's')
      if (remaining < 0) {
        console.warn('refresh credentials due to expired')
        tryRefresh()
      }
      const timer = setTimeout(() => {
        console.log('refresh credentials due to timer')
        tryRefresh()
      }, remaining)
      onCleanup(() => {
        console.log('clear refresh timer')
        clearTimeout(timer)
      })
    } else {
      console.warn('credentials have no expiration')
    }
  })

  return {
    provide: {
      authLoading,
      authPromise,
      authToken,
      authenticated,
      credentials,
      isAdmin,
      roles,
      session,
      user,
      userId,
      isProjectAdmin,
    },
  }
})
