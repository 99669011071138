<template>
  <el-container>
    <el-header :class="{ fixed: $route.meta.fixedHeader }">
      <AppLogo to="" />
    </el-header>
    <slot />
  </el-container>
</template>

<style lang="scss" scoped>
.el-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--el-color-primary);
  color: var(--el-color-white);

  &.fixed {
    position: fixed;
    top: 0;
  }
}
</style>
