/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getProject = /* GraphQL */ `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    title
    image
    description
    projectUrl
    locale
    userGroups
    rounds {
      nextToken
      __typename
    }
    profileDataItems {
      nextToken
      __typename
    }
    userProfiles {
      nextToken
      __typename
    }
    usersCount
    adminGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectQueryVariables,
  APITypes.GetProjectQuery
>;
export const listProjects = /* GraphQL */ `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      image
      description
      projectUrl
      locale
      userGroups
      usersCount
      adminGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectsQueryVariables,
  APITypes.ListProjectsQuery
>;
export const getProjectRound = /* GraphQL */ `query GetProjectRound($id: ID!) {
  getProjectRound(id: $id) {
    id
    projectRoundsId
    project {
      id
      title
      image
      description
      projectUrl
      locale
      userGroups
      usersCount
      adminGroups
      createdAt
      updatedAt
      __typename
    }
    title
    startTimestamp
    endTimestamp
    image
    description
    questions {
      nextToken
      __typename
    }
    answers {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectRoundQueryVariables,
  APITypes.GetProjectRoundQuery
>;
export const listProjectRounds = /* GraphQL */ `query ListProjectRounds(
  $filter: ModelProjectRoundFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectRounds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectRoundsId
      title
      startTimestamp
      endTimestamp
      image
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectRoundsQueryVariables,
  APITypes.ListProjectRoundsQuery
>;
export const projectRoundsByProjectRoundsIdAndStartTimestamp = /* GraphQL */ `query ProjectRoundsByProjectRoundsIdAndStartTimestamp(
  $projectRoundsId: ID!
  $startTimestamp: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProjectRoundFilterInput
  $limit: Int
  $nextToken: String
) {
  projectRoundsByProjectRoundsIdAndStartTimestamp(
    projectRoundsId: $projectRoundsId
    startTimestamp: $startTimestamp
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectRoundsId
      title
      startTimestamp
      endTimestamp
      image
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProjectRoundsByProjectRoundsIdAndStartTimestampQueryVariables,
  APITypes.ProjectRoundsByProjectRoundsIdAndStartTimestampQuery
>;
export const projectRoundsByProjectRoundsIdAndEndTimestamp = /* GraphQL */ `query ProjectRoundsByProjectRoundsIdAndEndTimestamp(
  $projectRoundsId: ID!
  $endTimestamp: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProjectRoundFilterInput
  $limit: Int
  $nextToken: String
) {
  projectRoundsByProjectRoundsIdAndEndTimestamp(
    projectRoundsId: $projectRoundsId
    endTimestamp: $endTimestamp
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectRoundsId
      title
      startTimestamp
      endTimestamp
      image
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProjectRoundsByProjectRoundsIdAndEndTimestampQueryVariables,
  APITypes.ProjectRoundsByProjectRoundsIdAndEndTimestampQuery
>;
export const getProjectRoundQuestion = /* GraphQL */ `query GetProjectRoundQuestion($id: ID!) {
  getProjectRoundQuestion(id: $id) {
    id
    projectRound {
      id
      projectRoundsId
      title
      startTimestamp
      endTimestamp
      image
      description
      createdAt
      updatedAt
      __typename
    }
    order
    title
    image
    description
    type
    options {
      nextToken
      __typename
    }
    publicResults
    feedbackOptions
    notApplicableOption
    hint
    section
    maxAnswers
    answers {
      nextToken
      __typename
    }
    results {
      optionId
      intValue
      booleanValue
      count
      __typename
    }
    createdAt
    updatedAt
    projectRoundQuestionsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectRoundQuestionQueryVariables,
  APITypes.GetProjectRoundQuestionQuery
>;
export const listProjectRoundQuestions = /* GraphQL */ `query ListProjectRoundQuestions(
  $filter: ModelProjectRoundQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectRoundQuestions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order
      title
      image
      description
      type
      publicResults
      feedbackOptions
      notApplicableOption
      hint
      section
      maxAnswers
      createdAt
      updatedAt
      projectRoundQuestionsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectRoundQuestionsQueryVariables,
  APITypes.ListProjectRoundQuestionsQuery
>;
export const getProjectRoundQuestionOption = /* GraphQL */ `query GetProjectRoundQuestionOption($id: ID!) {
  getProjectRoundQuestionOption(id: $id) {
    id
    projectRoundQuestionOptionsId
    projectRoundQuestion {
      id
      order
      title
      image
      description
      type
      publicResults
      feedbackOptions
      notApplicableOption
      hint
      section
      maxAnswers
      createdAt
      updatedAt
      projectRoundQuestionsId
      __typename
    }
    order
    title
    image
    answers {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectRoundQuestionOptionQueryVariables,
  APITypes.GetProjectRoundQuestionOptionQuery
>;
export const listProjectRoundQuestionOptions = /* GraphQL */ `query ListProjectRoundQuestionOptions(
  $filter: ModelProjectRoundQuestionOptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectRoundQuestionOptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectRoundQuestionOptionsId
      order
      title
      image
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectRoundQuestionOptionsQueryVariables,
  APITypes.ListProjectRoundQuestionOptionsQuery
>;
export const questionOptionsByQuestionId = /* GraphQL */ `query QuestionOptionsByQuestionId(
  $projectRoundQuestionOptionsId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProjectRoundQuestionOptionFilterInput
  $limit: Int
  $nextToken: String
) {
  questionOptionsByQuestionId(
    projectRoundQuestionOptionsId: $projectRoundQuestionOptionsId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectRoundQuestionOptionsId
      order
      title
      image
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QuestionOptionsByQuestionIdQueryVariables,
  APITypes.QuestionOptionsByQuestionIdQuery
>;
export const getUserAnswer = /* GraphQL */ `query GetUserAnswer($id: ID!) {
  getUserAnswer(id: $id) {
    id
    userId
    round {
      id
      projectRoundsId
      title
      startTimestamp
      endTimestamp
      image
      description
      createdAt
      updatedAt
      __typename
    }
    answers {
      nextToken
      __typename
    }
    userInfo {
      id
      email
      givenName
      familyName
      __typename
    }
    createdAt
    updatedAt
    projectRoundAnswersId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserAnswerQueryVariables,
  APITypes.GetUserAnswerQuery
>;
export const listUserAnswers = /* GraphQL */ `query ListUserAnswers(
  $filter: ModelUserAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      createdAt
      updatedAt
      projectRoundAnswersId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserAnswersQueryVariables,
  APITypes.ListUserAnswersQuery
>;
export const getQuestionAnswer = /* GraphQL */ `query GetQuestionAnswer($id: ID!) {
  getQuestionAnswer(id: $id) {
    id
    userId
    answer {
      id
      userId
      createdAt
      updatedAt
      projectRoundAnswersId
      __typename
    }
    projectRoundQuestionAnswersId
    question {
      id
      order
      title
      image
      description
      type
      publicResults
      feedbackOptions
      notApplicableOption
      hint
      section
      maxAnswers
      createdAt
      updatedAt
      projectRoundQuestionsId
      __typename
    }
    answerString
    answerInt
    answerFloat
    answerBoolean
    answerFile {
      key
      identityId
      level
      __typename
    }
    answerOptions {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userAnswerAnswersId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuestionAnswerQueryVariables,
  APITypes.GetQuestionAnswerQuery
>;
export const listQuestionAnswers = /* GraphQL */ `query ListQuestionAnswers(
  $filter: ModelQuestionAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuestionAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      projectRoundQuestionAnswersId
      answerString
      answerInt
      answerFloat
      answerBoolean
      createdAt
      updatedAt
      userAnswerAnswersId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuestionAnswersQueryVariables,
  APITypes.ListQuestionAnswersQuery
>;
export const questionAnswersByQuestionId = /* GraphQL */ `query QuestionAnswersByQuestionId(
  $projectRoundQuestionAnswersId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelQuestionAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  questionAnswersByQuestionId(
    projectRoundQuestionAnswersId: $projectRoundQuestionAnswersId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      projectRoundQuestionAnswersId
      answerString
      answerInt
      answerFloat
      answerBoolean
      createdAt
      updatedAt
      userAnswerAnswersId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QuestionAnswersByQuestionIdQueryVariables,
  APITypes.QuestionAnswersByQuestionIdQuery
>;
export const getProfileDataItem = /* GraphQL */ `query GetProfileDataItem($id: ID!) {
  getProfileDataItem(id: $id) {
    id
    type
    title
    orderIndex
    options
    project {
      id
      title
      image
      description
      projectUrl
      locale
      userGroups
      usersCount
      adminGroups
      createdAt
      updatedAt
      __typename
    }
    userProfileItems {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    projectProfileDataItemsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileDataItemQueryVariables,
  APITypes.GetProfileDataItemQuery
>;
export const listProfileDataItems = /* GraphQL */ `query ListProfileDataItems(
  $filter: ModelProfileDataItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfileDataItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      title
      orderIndex
      options
      createdAt
      updatedAt
      projectProfileDataItemsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfileDataItemsQueryVariables,
  APITypes.ListProfileDataItemsQuery
>;
export const getUserProjectProfile = /* GraphQL */ `query GetUserProjectProfile($id: ID!) {
  getUserProjectProfile(id: $id) {
    id
    userId
    userInfo {
      id
      email
      givenName
      familyName
      __typename
    }
    project {
      id
      title
      image
      description
      projectUrl
      locale
      userGroups
      usersCount
      adminGroups
      createdAt
      updatedAt
      __typename
    }
    userProfileItems {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    projectUserProfilesId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserProjectProfileQueryVariables,
  APITypes.GetUserProjectProfileQuery
>;
export const listUserProjectProfiles = /* GraphQL */ `query ListUserProjectProfiles(
  $filter: ModelUserProjectProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserProjectProfiles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      createdAt
      updatedAt
      projectUserProfilesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserProjectProfilesQueryVariables,
  APITypes.ListUserProjectProfilesQuery
>;
export const getUserProjectProfileItem = /* GraphQL */ `query GetUserProjectProfileItem($id: ID!) {
  getUserProjectProfileItem(id: $id) {
    id
    userId
    profileDataItem {
      id
      type
      title
      orderIndex
      options
      createdAt
      updatedAt
      projectProfileDataItemsId
      __typename
    }
    userProfile {
      id
      userId
      createdAt
      updatedAt
      projectUserProfilesId
      __typename
    }
    value
    createdAt
    updatedAt
    profileDataItemUserProfileItemsId
    userProjectProfileUserProfileItemsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserProjectProfileItemQueryVariables,
  APITypes.GetUserProjectProfileItemQuery
>;
export const listUserProjectProfileItems = /* GraphQL */ `query ListUserProjectProfileItems(
  $filter: ModelUserProjectProfileItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserProjectProfileItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      value
      createdAt
      updatedAt
      profileDataItemUserProfileItemsId
      userProjectProfileUserProfileItemsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserProjectProfileItemsQueryVariables,
  APITypes.ListUserProjectProfileItemsQuery
>;
export const getAnswerOptions = /* GraphQL */ `query GetAnswerOptions($id: ID!) {
  getAnswerOptions(id: $id) {
    id
    projectRoundQuestionOptionId
    questionAnswerId
    projectRoundQuestionOption {
      id
      projectRoundQuestionOptionsId
      order
      title
      image
      createdAt
      updatedAt
      __typename
    }
    questionAnswer {
      id
      userId
      projectRoundQuestionAnswersId
      answerString
      answerInt
      answerFloat
      answerBoolean
      createdAt
      updatedAt
      userAnswerAnswersId
      __typename
    }
    createdAt
    updatedAt
    userId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAnswerOptionsQueryVariables,
  APITypes.GetAnswerOptionsQuery
>;
export const listAnswerOptions = /* GraphQL */ `query ListAnswerOptions(
  $filter: ModelAnswerOptionsFilterInput
  $limit: Int
  $nextToken: String
) {
  listAnswerOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectRoundQuestionOptionId
      questionAnswerId
      createdAt
      updatedAt
      userId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAnswerOptionsQueryVariables,
  APITypes.ListAnswerOptionsQuery
>;
export const answerOptionsByProjectRoundQuestionOptionId = /* GraphQL */ `query AnswerOptionsByProjectRoundQuestionOptionId(
  $projectRoundQuestionOptionId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAnswerOptionsFilterInput
  $limit: Int
  $nextToken: String
) {
  answerOptionsByProjectRoundQuestionOptionId(
    projectRoundQuestionOptionId: $projectRoundQuestionOptionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectRoundQuestionOptionId
      questionAnswerId
      createdAt
      updatedAt
      userId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AnswerOptionsByProjectRoundQuestionOptionIdQueryVariables,
  APITypes.AnswerOptionsByProjectRoundQuestionOptionIdQuery
>;
export const answerOptionsByQuestionAnswerId = /* GraphQL */ `query AnswerOptionsByQuestionAnswerId(
  $questionAnswerId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAnswerOptionsFilterInput
  $limit: Int
  $nextToken: String
) {
  answerOptionsByQuestionAnswerId(
    questionAnswerId: $questionAnswerId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectRoundQuestionOptionId
      questionAnswerId
      createdAt
      updatedAt
      userId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AnswerOptionsByQuestionAnswerIdQueryVariables,
  APITypes.AnswerOptionsByQuestionAnswerIdQuery
>;
export const getI18nStrings = /* GraphQL */ `query GetI18nStrings($locale: String) {
  getI18nStrings(locale: $locale)
}
` as GeneratedQuery<
  APITypes.GetI18nStringsQueryVariables,
  APITypes.GetI18nStringsQuery
>;
