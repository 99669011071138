import { defineNuxtPlugin } from '#app'
export default defineNuxtPlugin({
  name: 'disableIosAutoZoom',
  parallel: true,
  setup() {
    const disableIOSTextFieldZoom = () => {
      if (!isIOS()) {
        return
      }
      const element = document.querySelector('meta[name=viewport]')
      if (element !== null) {
        let content = element.getAttribute('content')
        const scalePattern = /maximum -scale=[0-9.]+/g
        if (scalePattern.test(content)) {
          content = content.replace(scalePattern, 'maximum-scale=1.0')
        } else {
          content = [content, 'maximum-scale=1.0'].join(', ')
        }
        element.setAttribute('content', content)
      }
    }

    const isIOS = () => {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    }

    disableIOSTextFieldZoom()
  },
})
