<template>
  <template v-if="to">
    <nuxt-link :to="to">
      <LogoWhiteFull class="icon" />
    </nuxt-link>
  </template>
  <template v-else>
    <LogoWhiteFull class="icon" />
  </template>
</template>

<script>
import { defineNuxtComponent } from '#app'
import LogoWhiteFull from './icons/LogoWhiteFull.vue'
import { NuxtLink } from '#components'

export default defineNuxtComponent({
  props: {
    to: {
      type: String,
      default: '/',
    },
  },
  computed: {
    NuxtLink() {
      return NuxtLink
    },
  },
  components: { LogoWhiteFull },
})
</script>

<style lang="scss" scoped>
.icon {
  height: 38px;
  width: auto;
}
</style>
